import React, { useEffect, useState, Fragment } from 'react'
import api from 'util/api'
import moment from 'moment'
import Card from './card/index'
import Wallet from './wallet/index'
import Header from './header/index'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import ThemedModal from '../ThemedModal'
import Calculator from './calculator/index'
import UnstakeDialog from './unstake-dialog'
import Leaderboard from './leaderboard/index'
import StakeDialog from './stake-dialog/index'
import CooldownDialog from './cooldown-dialog'
import CooldownBanner from './cooldown-banner'
import SwapStatusDialog from './swap-status-dialog'
import StakeStatusDialog from './stake-status-dialog'
import BackgroundImage from '../images/background.png'
import ProblemsConnectingModal from './ProblemsConnectingModal'
import TransactionStatusDialog from './transaction-status-dialog'
import { useMediaQuery } from 'react-responsive'
import { Modal, Button, Checkbox } from 'rsuite'
import { StakingBalance } from './earnings/index'
import { now, get, compact, cloneDeep } from 'lodash'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { Text, Title, BottomCenter, TextContainer } from './components/index'
import { accountState, web3State, authSelector, termsOfUseState } from 'util/state'
import { isProduction, isStaging, responsiveWidthS, responsiveWidthM, COOLDOWN_PERIOD } from 'util/const'
import {
  swap,
  TOKENS,
  lockToken,
  getPenalty,
  getCooldown,
  WEB3_LOCK_TYPE,
  lockStmxV2Token,
  getAccountBalance,
  getCooldownAmount,
  swapIncreaseAllowance,
  lockTokenIncreaseAllowance,
  swapShouldIncreaseAllowance,
  lockTokenShouldIncreaseAllowance
} from 'util/web3'

interface StakeProps {
  isMobileBrowser: boolean
}

let errorTimer = 0

const REQUIRED_CONFIRMATIONS = 1

const Wrapper = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    margin-bottom: 5px;
  }

  margin-bottom: 50px;
  position: relative;
`

const Background = styled.img.attrs({ src: BackgroundImage })`
  width: 100%;
  height: 300px;
  z-index: -2;
  position: absolute;
`

const TitleWrapper = styled.div`
  margin: ${({ isMediumScreen }: { isMediumScreen: boolean }) =>
    isMediumScreen ? `43px 25px 43px 25px` : '90px 25px 43px 25px'};
  text-align: center;
  margin-bottom: ${({ isMediumScreen }: { isMediumScreen: boolean }) => (isMediumScreen ? '20%' : '10%')};
`

const RowContainerSpaceBetween = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const ModalHeader = styled.div`
  &:before {
    content: ' ';
    background-color: #dfe1e2;
    display: inline-block;
    height: 1px;
    position: absolute;
    right: 80px;
    bottom: 0;
    left: 100px;
  }

  color: rgb(44, 37, 60);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 15px;
  text-align: center;
  margin-top: 25px;
  position: relative;
  box-sizing: content-box;
`

const ModalContent = styled.div`
  color: rgb(44, 37, 60);
  font-family: Adelle;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  margin: 15px;
`

const ContinueButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`

const ContinueButton = styled(Button)`
  background: rgb(250, 46, 83);
  border-radius: 30px;
  height: 50px;
  width: 150px;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
`

const Stake = ({ isMobileBrowser }: StakeProps) => {
  const [authAccount, setAuthAccount] = useRecoilState(accountState)
  const web3state = useRecoilValue(web3State)
  const setAuth = useSetRecoilState(authSelector)
  const termsOfUseVersion = useRecoilValue(termsOfUseState)

  const [balance, setBalance] = useState<StakingBalance>({
    ATH: {
      loaded: false,
      locked: '-1',
      unlocked: '-1'
    },
    STMX: {
      loaded: false,
      locked: '-1',
      unlocked: '-1'
    },
    STMX_V2: {
      loaded: false,
      locked: '-1',
      unlocked: '-1'
    }
  })
  const [dataPoint, setDataPoint] = useState(now())
  const [firstLoad, setFirstLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showStakeStatusDialog, setShowStakeStatusDialog] = useState(false)
  const [lockType, setLockType] = useState<WEB3_LOCK_TYPE>(WEB3_LOCK_TYPE.LOCK)
  const [amount, setAmount] = useState('0')
  const [staking, setStaking] = useState(false)
  const [stakingModalError, setStakingModalError] = useState('')
  const [transactionDetails, setTransactionDetails] = useState(['', 0, false])
  const decimal = Number.isInteger(termsOfUseVersion) ? '.0' : ''
  const touVersion = 'touV' + termsOfUseVersion + decimal
  const consented = get(authAccount.account?.flags, `${touVersion}`, false)

  const [showConsent, setShowConsent] = useState(!consented)
  const [consentLoading, setConsentLoading] = useState(false)
  const [checkboxChecked, setCheckboxChecked] = useState(false)

  const [penalty, setPenalty] = useState(0)
  const [swapSuccess, setSwapSuccess] = useState(false)
  const [swapErrorMessage, setSwapErrorMessage] = useState('')
  const [swapAmount, setSwapAmount] = useState('0')
  const [showTransactionStatusDialog, setShowTransactionStatusDialog] = useState(false)
  const [showSwapStatusDialog, setShowSwapStatusDialog] = useState(false)
  const [increaseAllowanceLoading, setIncreaseAllowanceLoading] = useState<boolean | null>(null)
  const [transactionLoading, setTransactionLoading] = useState<boolean | null>(null)
  const [method, setMethod] = useState('')
  const [methodTransactionHash, setMethodTransactionHash] = useState('')
  const [showStakeDialog, setShowStakeDialog] = useState(false)
  const [showUnstakeDialog, setShowUnstakeDialog] = useState(false)
  const [showCooldownDialog, setShowCooldownDialog] = useState(false)
  const [tokenSelected, setTokenSelected] = useState(TOKENS.STMX_V2)
  const [cooldownTimer, setCooldownTimer] = useState(0)
  const [cooldownAmount, setCooldownAmount] = useState('')
  const [timerFormatted, setTimerFormatted] = useState('')

  const isMediumScreen = useMediaQuery({
    query: `(max-width: ${responsiveWidthM}px)`
  })

  const { networkId, wallet } = web3state

  const accountWallet = authAccount?.account?.stormxRewards?.wallet
  const doesNotUseStormXWallet = wallet && accountWallet && wallet !== accountWallet

  const transactionHash = transactionDetails[0] as string
  const confirmationNumber = transactionDetails[1] as number
  const stakingSucceeded = transactionDetails[2] as boolean

  let error: boolean | string = false
  if (networkId !== 0) {
    if (networkId === -1) {
      error = 'Unable to connect to Ethereum network. Check your wallet.'
      errorTimer = now()
    } else if (isProduction && networkId !== 1) {
      error = 'Change your network to Mainnet to continue.'
      errorTimer = now()
    } else if (isStaging && networkId !== 5) {
      error = 'Change your network to Goerli to continue.'
      errorTimer = now()
    }
  }
  if (!error && now() - errorTimer > 3000) {
    if (
      (balance.ATH.loaded &&
        BigNumber(balance.ATH.locked).isEqualTo(-1) &&
        BigNumber(balance.ATH.unlocked).isEqualTo(-1)) ||
      (balance.STMX.loaded &&
        BigNumber(balance.STMX.locked).isEqualTo(-1) &&
        BigNumber(balance.STMX.unlocked).isEqualTo(-1)) ||
      (balance.STMX_V2.loaded &&
        BigNumber(balance.STMX_V2.locked).isEqualTo(-1) &&
        BigNumber(balance.STMX_V2.unlocked).isEqualTo(-1)) ||
      (accountWallet && doesNotUseStormXWallet)
    ) {
      error = true
    }
  }

  const logout = async () => {
    await api.get('/signOut')
    setAuth({
      signedIn: false,
      token: undefined
    })
    setAuthAccount({ loading: false })
  }

  const stakeToken = async (lockType: WEB3_LOCK_TYPE, amount: string, token: string, isFakeUnstake: boolean) => {
    setLoading(true)
    setStaking(true)
    setShowStakeStatusDialog(true)
    setTransactionDetails(['', 0, false])
    setStakingModalError('')
    setLockType(lockType)
    setIncreaseAllowanceLoading(null)
    setTransactionLoading(null)

    if (token === TOKENS.STMX_V2) {
      stakeV2Token(lockType, amount, isFakeUnstake)
    } else {
      await lockToken(token === TOKENS.STMX, lockType, amount, {
        transactionHash: (hash: string) => {
          setAmount('0')
          setTransactionDetails([hash, 0, false])
          setStaking(false)
          return true
        },
        confirmation: (confirmationNumber: number, { transactionHash }) => {
          setTransactionDetails([transactionHash, confirmationNumber, false])
          if (confirmationNumber >= REQUIRED_CONFIRMATIONS) {
            setTransactionDetails([transactionHash, 0, true])
            setLoading(false)
            setDataPoint(now())
            return true
          }
        },
        error: (ex: Error) => {
          setStaking(false)
          setLoading(false)
          setAmount('0')
          setDataPoint(now())
          setStakingModalError(ex.message)
          return true
        }
      })
    }
  }

  const stakeV2Token = async (lockType: WEB3_LOCK_TYPE, amount: string, isFakeUnstake: boolean) => {
    setLoading(false)
    setMethod(lockType === WEB3_LOCK_TYPE.LOCK ? 'stake' : isFakeUnstake && amount === '0' ? 'cancel' : 'unstake')
    setShowTransactionStatusDialog(true)
    setStaking(true)
    setTransactionDetails(['', 0, false])
    setStakingModalError('')
    setLockType(lockType)

    const { shouldIncreaseAllowance, difference } = await lockTokenShouldIncreaseAllowance(amount)

    let increaseAllowanceSuccess = true

    if (shouldIncreaseAllowance) {
      setIncreaseAllowanceLoading(true)

      await lockTokenIncreaseAllowance(difference, {
        transactionHash: (hash: string) => {
          setMethodTransactionHash(hash)
          return true
        },
        confirmation: (confirmationNumber: number, { transactionHash }) => {
          increaseAllowanceSuccess = true
          return true
        },
        error: (ex: Error) => {
          increaseAllowanceSuccess = false
          setShowTransactionStatusDialog(false)
          setStakingModalError(ex.message)
          return true
        }
      })

      setIncreaseAllowanceLoading(false)
    }

    if (increaseAllowanceSuccess) {
      setIncreaseAllowanceLoading(false)
      setTransactionLoading(true)

      await lockStmxV2Token(lockType, amount, isFakeUnstake, {
        transactionHash: (hash: string) => {
          setAmount('0')
          setTransactionDetails([hash, 0, false])
          setMethodTransactionHash(hash)
          setStaking(false)
          return true
        },
        confirmation: (confirmationNumber: number, { transactionHash }) => {
          setTransactionDetails([transactionHash, confirmationNumber, false])
          if (confirmationNumber >= REQUIRED_CONFIRMATIONS) {
            if (lockType === WEB3_LOCK_TYPE.UNLOCK) {
              // clear cooldown
              if (
                (isFakeUnstake && amount === '0') || // cancelling unstake
                (!isFakeUnstake && amount > '0') // requesting real unstake
              ) {
                setCooldownTimer(0)
                setTimerFormatted('')
                setCooldownAmount('')
              }

              // create cooldown
              if (isFakeUnstake && BigNumber(amount).toNumber() > 0) {
                setCooldownTimer(1)
                setCooldownAmount(`${amount}`)
                setTimerFormatted(`${COOLDOWN_PERIOD} days`)
              }
            }

            setTransactionDetails([transactionHash, 0, true])
            // setLoading(false)
            setShowTransactionStatusDialog(false)
            setTransactionLoading(false)
            setDataPoint(now())
            return true
          }
        },
        error: (ex: Error) => {
          setStaking(false)
          setAmount('0')
          setDataPoint(now())
          setTransactionLoading(false)
          setStakingModalError(ex.message)
          setShowTransactionStatusDialog(false)
          return true
        }
      })
    }
  }

  const swapToken = async () => {
    try {
      setMethod('convert')
      setShowTransactionStatusDialog(true)

      const amount = BigNumber(balance.STMX.unlocked).toString()
      setSwapAmount(amount)

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { shouldIncreaseAllowance, difference, allowance } = await swapShouldIncreaseAllowance(amount)

      if (shouldIncreaseAllowance) {
        setIncreaseAllowanceLoading(true)

        await swapIncreaseAllowance(difference, {
          transactionHash: (hash: string) => {
            setMethodTransactionHash(hash)
            return true
          },
          confirmation: (confirmationNumber: number, { transactionHash }) => {
            return true
          },
          error: (ex: any) => {
            setSwapSuccess(false)
            setShowTransactionStatusDialog(false)
            setShowSwapStatusDialog(true)
            return true
          }
        })

        setIncreaseAllowanceLoading(false)
      }

      setIncreaseAllowanceLoading(false)
      setTransactionLoading(true)

      await swap(amount, {
        transactionHash: (hash: string) => {
          setMethodTransactionHash(hash)
          return true
        },
        confirmation: (confirmationNumber: number, { transactionHash }) => {
          return true
        },
        error: (ex: any) => {
          setSwapSuccess(false)
          setShowTransactionStatusDialog(false)
          setShowSwapStatusDialog(true)
          return true
        }
      })

      setTransactionLoading(false)

      setBalance({
        ATH: {
          loaded: true,
          locked: balance.ATH.locked,
          unlocked: balance.ATH.unlocked
        },
        STMX: {
          loaded: true,
          locked: balance.ATH.locked,
          unlocked: '0'
        },
        STMX_V2: {
          loaded: true,
          locked: balance.STMX_V2.locked,
          unlocked: BigNumber(balance.STMX_V2.unlocked).plus(amount).toString()
        }
      })

      setSwapSuccess(true)
      setShowTransactionStatusDialog(false)
      setShowSwapStatusDialog(true)
    } catch (error: any) {
      if (error.message) {
        setSwapErrorMessage(error.message)
      }

      setSwapSuccess(false)
      setShowTransactionStatusDialog(false)
      setShowSwapStatusDialog(true)
    }
  }

  useEffect(() => {
    const getStmxV2Penalty = async () => {
      const penalty = await getPenalty()
      setPenalty(penalty)
    }

    const getCooldownTokenAmount = async () => {
      const amount = await getCooldownAmount()
      setCooldownAmount(amount)
    }

    const getCooldownTimer = async () => {
      const timer = await getCooldown()
      getCooldownTokenAmount()
      setCooldownTimer(timer)

      const date = new Date(timer * 1000)

      if (moment(date).isAfter(new Date())) {
        const hours = differenceInHours(new Date(date), new Date())
        const minutes = differenceInMinutes(new Date(date), new Date())
        const days = Math.floor(hours / 24)

        if (days > 0) {
          setTimerFormatted(`${days} days`)
        } else if (hours >= 1) {
          setTimerFormatted(`${hours} ${hours === 1 ? 'hour' : 'hours'}`)
        } else {
          setTimerFormatted(`${minutes === 0 ? 1 : minutes} ${minutes <= 1 ? 'minute' : 'minutes'}`)
        }
      }
    }

    getStmxV2Penalty()
    getCooldownTimer()
  }, [])

  useEffect(() => {
    const getBalance = async () => {
      setLoading(true)
      try {
        const stmxAccountBalance = await getAccountBalance(TOKENS.STMX)
        const stmxV2AccountBalance = await getAccountBalance(TOKENS.STMX_V2)
        const athAccountBalance = await getAccountBalance(TOKENS.ATH)

        setBalance({
          ATH: {
            loaded: true,
            locked: BigNumber(athAccountBalance.locked).toString(),
            unlocked: BigNumber(athAccountBalance.unlocked).toString()
          },
          STMX: {
            loaded: true,
            locked: BigNumber(stmxAccountBalance.locked).toString(),
            unlocked: BigNumber(stmxAccountBalance.unlocked).toString()
          },
          STMX_V2: {
            loaded: true,
            locked: BigNumber(stmxV2AccountBalance.locked).toString(),
            unlocked: BigNumber(stmxV2AccountBalance.unlocked).toString()
          }
        })
        setFirstLoad(true)
      } catch (ex: any) {
        setBalance({
          ATH: {
            loaded: true,
            locked: '-1',
            unlocked: '-1'
          },
          STMX: {
            loaded: true,
            locked: '-1',
            unlocked: '-1'
          },
          STMX_V2: {
            loaded: true,
            locked: '-1',
            unlocked: '-1'
          }
        })
        setFirstLoad(true)
      } finally {
        setLoading(false)
      }
    }

    getBalance()
  }, [dataPoint, networkId, wallet])

  return (
    <Wrapper>
      <Header />
      <Background />

      <TitleWrapper isMediumScreen={isMediumScreen}>
        <Fragment>
          <Title>
            Home of the Triple Stack{' '}
            <span role='img' aria-label='burger'>
              🍔
            </span>
          </Title>
          <Text>
            <Text subtitle>Crypto CashBack - </Text>
            <Text subtitle>Member Bonus - </Text>
            <Text subtitle>Staking - </Text>
            <Text
              subtitle
              underline
              onClick={() => {
                window.open('https://youtu.be/vkK6yoVXY4E')
              }}
            >
              Learn more
            </Text>
          </Text>
        </Fragment>
      </TitleWrapper>

      <CooldownBanner
        timer={cooldownTimer}
        balance={balance}
        stakeToken={stakeToken}
        timerFormatted={timerFormatted}
        cooldownAmount={cooldownAmount}
      />

      {Object.values(TOKENS).map((token) => {
        return (
          <Card
            key={token}
            token={token}
            error={error}
            balance={balance}
            loading={loading}
            firstLoad={firstLoad}
            swapToken={swapToken}
            cooldownAmount={cooldownAmount}
            first={token === TOKENS.STMX_V2}
            isMobileBrowser={isMobileBrowser}
            setTokenSelected={setTokenSelected}
            setShowStakeDialog={setShowStakeDialog}
            setShowUnstakeDialog={setShowUnstakeDialog}
            setShowCooldownDialog={setShowCooldownDialog}
            showStakeStatusDialog={showStakeStatusDialog}
          />
        )
      })}

      <Calculator />
      <Leaderboard isMobileBrowser={isMobileBrowser} />

      <BottomCenter>
        {isMediumScreen && (
          <Fragment>
            <Wallet />
            <Text signOut underline onClick={() => logout()}>
              Sign out
            </Text>
          </Fragment>
        )}

        <TextContainer>
          <Text terms>© Copyright {new Date().getFullYear()} StormX Singapore PTE LTD.</Text>
        </TextContainer>

        <TextContainer>
          <Text terms underline onClick={() => window.open('https://stormx.io/terms-of-use')}>
            Terms of use
          </Text>
          <Text terms line>
            |
          </Text>
          <Text terms underline onClick={() => window.open('https://stormx.io/privacy-policy')}>
            Privacy policy
          </Text>
        </TextContainer>
      </BottomCenter>

      <CooldownDialog
        token={tokenSelected}
        timeFormatted={timerFormatted}
        cooldownAmount={cooldownAmount}
        setTokenSelected={setTokenSelected}
        showCooldownDialog={showCooldownDialog}
        setShowUnstakeDialog={setShowUnstakeDialog}
        setShowCooldownDialog={setShowCooldownDialog}
      />

      <StakeDialog
        amount={amount}
        balance={balance}
        penalty={penalty}
        token={tokenSelected}
        setAmount={setAmount}
        stakeToken={stakeToken}
        showStakeDialog={showStakeDialog}
        setShowStakeDialog={setShowStakeDialog}
      />

      <UnstakeDialog
        amount={amount}
        balance={balance}
        loading={loading}
        penalty={penalty}
        token={tokenSelected}
        setAmount={setAmount}
        stakeToken={stakeToken}
        cooldownAmount={cooldownAmount}
        showUnstakeDialog={showUnstakeDialog}
        setShowUnstakeDialog={setShowUnstakeDialog}
      />

      <StakeStatusDialog
        staking={staking}
        lockType={lockType}
        networkId={networkId}
        token={tokenSelected}
        transactionHash={transactionHash}
        stakingSucceeded={stakingSucceeded}
        stakingModalError={stakingModalError}
        confirmationNumber={confirmationNumber}
        showStakeStatusDialog={showStakeStatusDialog}
        setShowStakeStatusDialog={setShowStakeStatusDialog}
      />

      <TransactionStatusDialog
        method={method}
        transactionLoading={transactionLoading}
        methodTransactionHash={methodTransactionHash}
        increaseAllowanceLoading={increaseAllowanceLoading}
        showTransactionStatusDialog={showTransactionStatusDialog}
        setShowTransactionStatusDialog={setShowTransactionStatusDialog}
      />

      <SwapStatusDialog
        amount={swapAmount}
        success={swapSuccess}
        error={swapErrorMessage}
        swapTransaction={methodTransactionHash}
        setShowStakeDialog={setShowStakeDialog}
        showSwapStatusDialog={showSwapStatusDialog}
        setShowSwapStatusDialog={setShowSwapStatusDialog}
      />

      <ProblemsConnectingModal
        error={error}
        usedWallet={wallet}
        wallet={accountWallet}
        isMobileBrowser={isMobileBrowser}
      />

      <ThemedModal overflow={false} show={showConsent}>
        <Modal.Header onHide={() => setShowConsent(false)}>
          <ModalHeader>Updated Terms of Use</ModalHeader>
        </Modal.Header>
        <Modal.Body>
          <RowContainerSpaceBetween>
            <Checkbox
              checked={checkboxChecked}
              onChange={(value, checked) => {
                setCheckboxChecked(checked)
              }}
            />
            <ModalContent>
              By checking this box, you agree to{' '}
              <a href='https://stormx.io/terms-of-use' rel='noopener noreferrer' target='_blank'>
                StormX's Terms of Use
              </a>{' '}
              and acknowledge that STMX and ATH tokens are used for memberships and governance respectively with no
              promise of financial value.
            </ModalContent>
          </RowContainerSpaceBetween>

          <ContinueButtonContainer>
            <ContinueButton
              appearance='primary'
              loading={consentLoading}
              disabled={!checkboxChecked}
              onClick={async () => {
                if (checkboxChecked) {
                  setConsentLoading(true)
                  const operations = [
                    {
                      data: {
                        [touVersion]: true
                      },
                      op: 'add',
                      path: '/flags'
                    }
                  ]

                  try {
                    const { data } = await api.patch('/account', {
                      input: compact(operations)
                    })

                    if (data && data.success) {
                      const authAccountClone = cloneDeep(authAccount)

                      if (authAccountClone.account) {
                        authAccountClone.account.flags = {
                          [touVersion]: true
                        }

                        setShowConsent(false)
                        setConsentLoading(false)
                        setAuthAccount(authAccountClone)
                      }
                    }
                  } catch (error) {
                    setConsentLoading(false)
                  }
                }
              }}
            >
              Continue
            </ContinueButton>
          </ContinueButtonContainer>
        </Modal.Body>
      </ThemedModal>
    </Wrapper>
  )
}
export default Stake
