import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import AmountControl, { AmountControlType } from '../AmountControl'
import { get } from 'lodash'
import { Form, FormGroup } from 'rsuite'
import { StakingBalance } from '../earnings/index'
import { ContractEvents, TOKENS, WEB3_LOCK_TYPE } from 'util/web3'
import { Text, StakeButton, PercentageButton, PercentagesGroup } from './components/index'

const percentages = [10, 25, 50, 100]

interface UnstakeFormProps {
  token: string
  amount: string
  loading: boolean
  balance: StakingBalance
  recommendedSelected: boolean
  setAmount: React.Dispatch<React.SetStateAction<string>>
  setDisplayForm: React.Dispatch<React.SetStateAction<boolean>>
  setShowUnstakeDialog: React.Dispatch<React.SetStateAction<boolean>>
  stakeToken: (
    lockType: WEB3_LOCK_TYPE,
    amount: string,
    token: string,
    fakeUnstake: boolean,
    events?: ContractEvents
  ) => any
}

const UnstakeForm = ({
  token,
  amount,
  balance,
  loading,
  setAmount,
  stakeToken,
  setDisplayForm,
  recommendedSelected,
  setShowUnstakeDialog
}: UnstakeFormProps) => {
  const [percentage, setPercentage] = useState(0)
  const tokenBalance = get(balance, `${token.replace(' ', '_')}`)

  return (
    <Form>
      <AmountControl
        balance={balance}
        loading={loading}
        setAmount={setAmount}
        amount={amount}
        token={token}
        type={AmountControlType.UNLOCK}
      />

      <FormGroup>
        <br />

        <PercentagesGroup justified>
          {percentages.map((p) => (
            <PercentageButton
              selected={p === percentage}
              key={p}
              onClick={() => {
                if (p === 100) {
                  setAmount(BigNumber(tokenBalance.locked).toString())
                } else {
                  const result = BigNumber(tokenBalance.locked * (p / 100)).toString()
                  setAmount(result)
                }

                setPercentage(p)
              }}
            >
              <Text time timeSelected={p === percentage}>
                {p}%
              </Text>
            </PercentageButton>
          ))}
        </PercentagesGroup>

        <StakeButton
          disabled={!tokenBalance.locked || !+amount || BigNumber(tokenBalance.locked).isLessThan(amount)}
          onClick={() => {
            if (token === TOKENS.STMX_V2) {
              setDisplayForm(true)
            } else {
              if (tokenBalance.locked && BigNumber(tokenBalance.locked).isGreaterThan(0)) {
                setShowUnstakeDialog(false)
                stakeToken(WEB3_LOCK_TYPE.UNLOCK, amount, token, recommendedSelected)
              }
            }
          }}
        >
          <Text stake>{token === TOKENS.STMX_V2 ? 'Continue' : 'Unstake tokens'}</Text>
        </StakeButton>
      </FormGroup>
    </Form>
  )
}

export default UnstakeForm
