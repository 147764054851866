import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import ThemedModal from '../../ThemedModal'
import UnstakeForm from '../unstake-form/index'
import { get } from 'lodash'
import { Modal } from 'rsuite'
import { useRecoilValue } from 'recoil'
import { stakingState } from 'util/state'
import { COOLDOWN_PERIOD } from 'util/const'
import { numberWithCommas } from 'util/utils'
import { StakingBalance } from '../earnings/index'
import { ContractEvents, WEB3_LOCK_TYPE, TOKENS } from 'util/web3'
import {
  StakingV2SummaryBase,
  resolveStakingSummary,
  STMX_FIXED_SIGNIFICANCE,
  StakingCalculationValues,
  calculateStakingV2Earned
} from '@stormx/calculate-staking'
import {
  Text,
  EmptyDiv,
  IconInfo,
  RightArrow,
  RadioButton,
  ModalHeader,
  RowContainer,
  ListContainer,
  ContinueButton,
  OptionContainer,
  CenterContainer,
  RadioButtonActive
} from './components/index'

interface UnstakeDialogProps {
  token: string
  amount: string
  penalty: number
  loading: boolean
  cooldownAmount: string
  balance: StakingBalance
  showUnstakeDialog: boolean
  setAmount: React.Dispatch<React.SetStateAction<string>>
  setShowUnstakeDialog: React.Dispatch<React.SetStateAction<boolean>>
  stakeToken: (
    lockType: WEB3_LOCK_TYPE,
    amount: string,
    token: string,
    fakeUnstake: boolean,
    events?: ContractEvents
  ) => any
}

const UnstakeDialog = ({
  token,
  amount,
  penalty,
  balance,
  loading,
  setAmount,
  stakeToken,
  cooldownAmount,
  showUnstakeDialog,
  setShowUnstakeDialog
}: UnstakeDialogProps) => {
  const [displayForm, setDisplayForm] = useState(false)
  const [stakingProfit, setStakingProfit] = useState('')
  const [recommendedSelected, setRecommendedSelected] = useState(true)
  const tokenBalance = get(balance, `${token.replace(' ', '_')}`)
  const unstakeDate = moment(new Date()).add(COOLDOWN_PERIOD, 'days').format('MMM Do YY')
  const stakingstate = useRecoilValue(stakingState)
  /* eslint-disable-next-line */
  const stakingAccount = {
    ...stakingstate,
    summary: get(stakingstate, 'summary.STMX_V2', undefined)
  }
  const rate = stakingAccount?.summary?.rate || 0
  const calculated = new BigNumber(rate)
    .multipliedBy(BigNumber(amount).toNumber())
    .multipliedBy(14 / (moment().isLeapYear() ? 366 : 365))
  const lostRewards = calculated.isNaN() ? 0 : numberWithCommas(calculated.toFixed(5))

  useEffect(() => {
    const calculateStakingProfit = () => {
      if (stakingAccount.summary) {
        if (stakingAccount.summary.disconnectedRewardsCurrrentWeek) {
          setStakingProfit(StakingCalculationValues.UNLINKED_REWARDS_WALLET_THIS_CYCLE)
        } else {
          const stakingSummary = resolveStakingSummary(stakingAccount.summary)
          const earnings = calculateStakingV2Earned(stakingSummary as StakingV2SummaryBase)
          if (earnings.isLessThan(0)) {
            setStakingProfit(StakingCalculationValues.UNSTAKED_THIS_CYCLE)
          } else {
            setStakingProfit(earnings.toFixed(18))
          }
        }
      } else {
        setStakingProfit('')
      }
    }

    const interval = setInterval(() => calculateStakingProfit(), 50)
    return () => clearInterval(interval)
  }, [stakingAccount])

  return (
    <ThemedModal
      overflow={false}
      show={showUnstakeDialog}
      onExit={() => {
        setAmount('0')
        setDisplayForm(false)
      }}
    >
      <Modal.Header onHide={() => setShowUnstakeDialog(false)}>
        <ModalHeader>Unstake your {token} tokens</ModalHeader>
      </Modal.Header>
      <div>
        {token === TOKENS.STMX_V2 && displayForm && (
          <Fragment>
            <OptionContainer selected={recommendedSelected} onClick={() => setRecommendedSelected(true)}>
              {recommendedSelected ? (
                <RadioButtonActive />
              ) : (
                <RadioButton onClick={() => setRecommendedSelected(true)} />
              )}

              <RowContainer column>
                <RowContainer withSpaceBetween>
                  <Text optionTitle>Unstake in {COOLDOWN_PERIOD} days</Text>
                  <Text recommended>Recommended</Text>
                </RowContainer>
                <Text optionDescription>
                  No {penalty}% penalty, but you will not earn additional staking rewards during the {COOLDOWN_PERIOD}{' '}
                  days. You will forego earning {lostRewards} STMX tokens. You can unstake on {unstakeDate}.
                </Text>
              </RowContainer>
            </OptionContainer>

            <OptionContainer addMargin selected={!recommendedSelected} onClick={() => setRecommendedSelected(false)}>
              {recommendedSelected ? (
                <RadioButton onClick={() => setRecommendedSelected(false)} />
              ) : (
                <RadioButtonActive />
              )}

              <RowContainer column>
                <Text optionTitle>Unstake your tokens today</Text>
                <Text optionDescription>Penalties you'll incur:</Text>
                <ListContainer>
                  <li>
                    <Text optionDescription listItem>
                      Lose staking rewards -{' '}
                      {numberWithCommas(new BigNumber(parseFloat(stakingProfit)).toFixed(STMX_FIXED_SIGNIFICANCE))}
                    </Text>
                  </li>
                  <li>
                    <Text optionDescription listItem>
                      {penalty}% of your unstaked STMX - {(penalty / 100) * BigNumber(amount).toNumber()}
                    </Text>
                  </li>
                </ListContainer>
              </RowContainer>
            </OptionContainer>

            {cooldownAmount !== '0' && (
              <Fragment>
                <IconInfo />

                <Text info>
                  Please note that by clicking "unstake tokens" your existing cooldown period for {cooldownAmount} STMX
                  will be cancelled and a new {COOLDOWN_PERIOD} day cooldown for {amount} STMX will be started.
                </Text>
              </Fragment>
            )}

            <ContinueButton
              disabled={!tokenBalance.locked || !+amount || BigNumber(tokenBalance.locked).isLessThan(amount)}
              onClick={() => {
                if (tokenBalance.locked && tokenBalance.locked > 0) {
                  setShowUnstakeDialog(false)
                  stakeToken(WEB3_LOCK_TYPE.UNLOCK, amount, token, recommendedSelected)
                }
              }}
            >
              <EmptyDiv />
              <Text button>Unstake tokens</Text>
              <RightArrow />
            </ContinueButton>

            <CenterContainer goBack onClick={() => setDisplayForm(false)}>
              <Text goBack>Go back</Text>
            </CenterContainer>
          </Fragment>
        )}

        {!displayForm && (
          <div>
            <CenterContainer>
              <Text locked>
                You have {numberWithCommas(tokenBalance.locked)} {token} tokens staked.
              </Text>
            </CenterContainer>

            <UnstakeForm
              token={token}
              amount={amount}
              balance={balance}
              loading={loading}
              setAmount={setAmount}
              stakeToken={stakeToken}
              setDisplayForm={setDisplayForm}
              recommendedSelected={recommendedSelected}
              setShowUnstakeDialog={setShowUnstakeDialog}
            />
          </div>
        )}
      </div>
    </ThemedModal>
  )
}

export default UnstakeDialog
